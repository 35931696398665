<template>
  <div class="filter">
    <div class="filter-block container flex">
      <ul class="filter__items flex">
        <li class="filter__item search">
          <input
            v-model="search"
            class="filter__search search-input"
            type="text"
            placeholder="Поиск .."
            v-on:keyup.enter="filterOrders()"
            @focusout="filterOrders()"
          />
          <img class="search-icon" src="@/assets/png/search-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
            v-model="status"
            class="filter__item-select main__select"
            :options="listStatus"
            text-field="name"
            value-field="id"
            @change="filterOrders()"
          />
          <img class="main__select-icon" src="@/assets/png/select-icon.png" alt="" />
        </li>
        <li class="filter__item">
          <b-form-select
            v-model="address"
            class="filter__item-select main__select select-map"
            :options="listCities"
            text-field="name"
            value-field="id"
            @input="filterOrders()"
          />
        </li>
        <li class="filter__item">
          <button class="filter__item-btn" @click="dateShow = !dateShow">
            Дата создания
            <b-icon
              class="filter__item-btn-icon icon-right"
              style="color: #707070; margin-left: 10px"
              icon="calendar4-week"
            />
          </button>
          <div v-show="dateShow" class="sub-header__calendar">
            <vc-date-picker
              v-model="range"
              class="add__calendar"
              color="green"
              :rows="1"
              is-range
              @input="inputData()"
            />
            <button class="btn btn-primary mt-2" @click="hideModal()">Ok</button>
            <button class="btn btn-primary mt-2" @click="inputDataClean('')">Очистить</button>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="dateShow" class="main-close" @click="dateShow = false" />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      selected: null,
      range: [],
      status: null,
      address: null,
      date: null,
      dateShow: false,
      search: "",
      options1: [
        { value: null, text: "Тип доставки" },
        { value: "a", text: "This is First option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      options2: [
        { value: null, text: "Местоположение" },
        { value: "a", text: "This is First option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
      options5: [
        { value: null, text: "Все заказы" },
        { value: "a", text: "This is First option" },
        { value: "d", text: "This one is disabled", disabled: true },
      ],
    };
  },
  methods: {
    filterOrders() {

      const searchURL = new URL(window.location);
      var changed = false;

      if (this.search !== "" && this.$store.state.textFilter !== this.search) {
        this.$store.commit("textFilter", this.search);
        searchURL.searchParams.set('search', this.search);
        changed = true;
      }

      if (this.address !== null && this.$store.state.addressFilter !== this.address) {
        this.$store.commit("addressFilter", this.address);
        searchURL.searchParams.set('region_id', this.address);
        changed = true;
      }

      if (this.status !== null && this.$store.state.statusFilter !== this.status) {
        this.$store.commit("statusFilter", this.status);
        searchURL.searchParams.set('route_status', this.status);
        changed = true;
      }
      
      if (changed)
        window.history.pushState({}, '', searchURL)
    },
    inputData() {
      this.range.start = new Date(this.range.start).toISOString().split("T")[0];
      this.range.end = new Date(this.range.end).toISOString().split("T")[0];
      this.$store.commit("dateFilter", this.range);

      // переводим данные в ссылку
      const searchURL = new URL(window.location);

      searchURL.searchParams.set('from_date', this.range.start);
      searchURL.searchParams.set('to_date', this.range.end);

      window.history.pushState({}, '', searchURL)
    },
    inputDataClean() {
      this.range = [];
      this.$store.commit("dateFilter", null);
      this.hideModal();

      // убираем из ссылки
      const searchURL = new URL(window.location);
      searchURL.searchParams.delete('from_date');
      searchURL.searchParams.delete('to_date');
      window.history.pushState({}, '', searchURL)
    },
    hideModal() {
      this.dateShow = false;
    },
    setQueries() {
      
      // если в ссылке имеются фильтры, применяем их к стейту
      if (this.$route.query.search !== undefined) {
        this.search = this.$route.query.search;
        this.$store.commit("textFilter", this.search);
      }
      if (this.$route.query.region_id !== undefined) {
        this.address = this.$route.query.region_id;
        this.$store.commit("addressFilter", this.address);
      }
      if (this.$route.query.route_status !== undefined) {
        this.status = this.$route.query.route_status;
        this.$store.commit("statusFilter", this.status);
      }
      if (this.$route.query.from_date !== undefined && this.$route.query.to_date !== undefined) {
        this.range.start = this.$route.query.from_date;
        this.range.end = this.$route.query.from_date; 
      }

      var range = this.range.length ? this.range : null 
      this.$store.commit("dateFilter", range);
    }

  },
  computed: {
    ...mapState(["statusList", "citiesProducts", "addressFilter"]),
    listStatus() {
      if (this.statusList) {
        const inProcessIndex = this.statusList.indexOf("В пути");
        const bufferStatusList = this.statusList;
        if (inProcessIndex !== -1)
          bufferStatusList[inProcessIndex] = {
            id: "in_process",
            name: "В пути",
          };
        const arr = [
          { id: null, name: "Выберите статус" },
          ...bufferStatusList,
          "Регион не найден",
        ];
        return arr;
      } else {
        return [{ id: null, name: "Выберите статус" }];
      }
    },
    listCities() {
      if (this.citiesProducts) {
        return [{ id: null, name: "Местоположение" }, ...this.citiesProducts];
      }
      return [{ id: null, name: "Местоположение" }];
    },
  },
  async created() {
    if (!this.statusList)
      await this.$store.dispatch("getStatusList");

    if (!this.citiesProducts)
      await this.$store.dispatch("getCitiesProducts");
    this.setQueries()
  },
};
</script>

<style scoped>
.filter {
  padding: 10px 0 10px 0;
  background-color: #fff;
  border-top: 1px solid #f2f2f8;
  position: relative;
}
.filter__items {
  justify-content: flex-start;
  flex-wrap: wrap;
}
.filter__item {
  position: relative;
  font-size: 14px;
  margin-top: 10px;
}

.add__calendar {
  position: static;
  background: #fff;
}
.filter__item:not(:last-child) {
  margin-right: 10px;
}
.filter__search {
  max-width: 170px;
  margin-right: 20px;
  color: #acacac;
}
.sub-header__calendar {
  position: absolute;
  left: 22%;
  top: 45px;
  z-index: 4;
  background: #f8f8f8;
  padding: 10px;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
}
.filter__search-address {
  font-size: 1rem;
}
.filter__item-select {
  /* background: #FFFFFF; */
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  color: #707070;
  width: 180px;
}
.select-map {
  width: 180px;
}
.main__select-icon {
  top: 50%;
}
.filter__item-btn {
  height: 100%;
  background: #ffffff;
  border: 1px solid #e0e9fa;
  border-radius: 8px;
  padding: 10px 35px 10px 10px;
  color: #707070;
  text-align: start;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
}

.filter__item-btn-icon {
  right: 3px;
}

.filter-btn {
  position: relative;
  margin-left: 30px;
  margin-top: 10px;
}

.filter-btn__icon {
  margin-right: 12px;
}

.filter__change {
  width: auto;
  padding-bottom: 15px;
  margin-top: 10px;
}

.filter__change-text {
  margin: 0px 12px;
}

.filter-label {
  background: var(--secondary-color);
}

.filter-label__defaul {
  background: #83b1f4;
}
</style>
